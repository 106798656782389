<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="card rounded-3">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h6 class="fs-16 mb-0">{{ $t('coupon.list') }}</h6>
                <b-button :disabled="disabled" @click="createCoupon" variant="dark" class="waves-effect waves-light w-sm pt-2 pb-2"
                    data-text="追加">
                    <span class="fs-12">{{ $t('t-create') }}</span>
                </b-button>
            </div>
          <div class="table-responsive">
            <TableCommon :endpoint="ADMIN_API_PATH.ADMIN_COUPON" ref="tableCoupon" :columns="columns"
                         :query="state.query" />
          </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import { reactive, ref, h, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ADMIN_API_PATH, ROLES } from "@/constants";
import TableCommon from "@/components/common/TableCommonVue3.vue";
import moment from "moment";
import Swal from "sweetalert2";
import { couponService } from '@/services'
import { useStore } from "vuex";

export default {
    components: {
        Layout,
        PageHeader,
        TableCommon,
    },
    setup() {
        const router = useRouter()
        const title = i18n.global.t('coupon.management')
        const items = [
            {
                text: i18n.global.t('t-home'),
                href: "/"
            },
            {
                text: i18n.global.t('coupon.list'),
                active: true
            }
        ];
        const tableCoupon = ref(null)
        const store = useStore()
        const facilityId = computed(() => store.getters['settings/getFacilityId'])
        const currentUser = computed(() => store.getters['auth/currentUser']);
        const disabled = computed(() => (currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT) || !currentUser.value || (facilityId.value === null || facilityId.value === ''));

        const couponClassName = {
            [i18n.global.t('coupon.valid')]: "status status-valid",
            [i18n.global.t('coupon.invalid')]: "status status-invalid",
            [i18n.global.t('coupon.expiresStatus')]: "status status-expired"
        }

        const state = reactive({
            query: {
                search: '',
                page: 1,
                pageSize: 10,
                sortBy: '',
                sortOrder: 'ASC',
                facilityId: facilityId.value
            }
        })

        const formatExpires = (coupon) => {
            const start = moment.unix(coupon.startTime).format('YYYY-MM-DD HH:mm')
            const end = moment.unix(coupon.endTime).format('YYYY-MM-DD HH:mm')
            return `${start} ~ ${end}`
        }

        const formatDate = (dateString) => {
            return moment(dateString).format('YYYY-MM-DD HH:mm:ss')
        }

        const columns = [
            {
                sortable: false,
                label: '',
                type: 'stt',
                maxWidth: 200,
                getShowColumn: (hasItem) =>  hasItem,
                headerClass: 'text-center',
                cellClass: 'text-center',
                renderCell: (coupon, rowIndex) => {
                    return h('span', rowIndex + 1)
                },
            },
            {
                key: 'name',
                sortable: true,
                label: i18n.global.t('coupon.name'),
                maxWidth: 200,
                headerClass: 'text-center',
                cellClass: 'text-center',
            },
            {
                key: 'code',
                sortable: true,
                label: i18n.global.t('coupon.code'),
                maxWidth: 120,
                headerClass: 'text-center',
                cellClass: 'text-center',
            },
            {
                key: 'status',
                sortable: true,
                label: i18n.global.t('coupon.status'),
                maxWidth: 200,
                headerClass: 'text-center',
                cellClass: 'text-center',
                renderCell: (coupon) => {
                    const className = couponClassName[coupon.status]
                    return h('label', { class: className }, coupon.status)
                }
            },
            {
                key: 'quantity',
                sortable: false,
                label: i18n.global.t('coupon.limit'),
                maxWidth: 200,
                headerClass: 'text-center margin-header',
                cellClass: 'text-center',
                renderCell: (coupon) => {
                    let text = coupon.quantity ? coupon.quantity : i18n.global.t('coupon.infinite')
                    return h('span', text)
                }
            },
            {
                key: 'maxNumberInDay',
                sortable: true,
                label: i18n.global.t('coupon.limitOnday'),
                maxWidth: 200,
                headerClass: 'text-center',
                cellClass: 'text-center',
                renderCell: (coupon) => {
                    let text = coupon.maxNumberInDay ? i18n.global.t('coupon.have') : i18n.global.t('coupon.havent')
                    return h('span', text)
                }
            },
            {
                key: 'expires',
                sortable: true,
                label: i18n.global.t('coupon.expires'),
                maxWidth: 230,
                headerClass: 'text-center',
                cellClass: 'text-center',
                renderCell: (coupon) => {
                    return h('span', formatExpires(coupon))
                },
                tooltip: (coupon) => {
                    return formatExpires(coupon)
                },
                sortKey: 'startTime'
            },
            {
                key: 'createdAt',
                type: 'datetime',
                sortable: true,
                label: i18n.global.t('coupon.createdAt'),
                maxWidth: 200,
                headerClass: 'text-center',
                cellClass: 'text-center',
                renderCell: (coupon) => {
                    return h('span', formatDate(coupon.createdAt))
                },
                tooltip: (coupon) => {
                    return formatDate(coupon.createdAt)
                }
            },
            {
                key: 'updatedAt',
                type: 'datetime',
                sortable: true,
                label: i18n.global.t('coupon.updatedAt'),
                maxWidth: 200,
                headerClass: 'text-center',
                cellClass: 'text-center',
                renderCell: (coupon) => {
                    return h('span', formatDate(coupon.updatedAt))
                },
                tooltip: (coupon) => {
                    return formatDate(coupon.updatedAt)
                }
            },
            {
                key: 'action',
                type: 'action',
                getShowColumn: (hasItem) =>  hasItem,
                sortable: false,
                label: '',
                renderCell: (coupon) => {
                    return <div class="d-flex gap-2">
                        <b-button disabled={disabled.value} size="md" onClick={() => viewCoupon(coupon.id)} variant="primary">{i18n.global.t('t-edit')}</b-button>
                        <b-button disabled={disabled.value}  size="md" onClick={() => deleteCoupon(coupon)} variant="danger">{i18n.global.t('t-delete')}</b-button>
                    </div>
                }
            }
        ]

        const viewCoupon = (couponId) => {
            if(disabled.value) return
            router.push({
                name: "coupon_update", params: {
                    couponId
                }
            })
        }

        const deleteCoupon = async (coupon) => {
            if(disabled.value) return
            try {
                const accept = await Swal.fire({
                    title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('coupon.deleteMsg')}</h2>`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: i18n.global.t('yes'),
                    cancelButtonText: i18n.global.t('cancel'),
                });
                if (accept.value) {
                    await couponService.deleteCoupon(coupon.id)
                    tableCoupon.value.refreshTable()
                }

            } catch (e) {
                console.log(e, 'deleteCoupon')
            }
        }

        const createCoupon = () => {
          if (disabled.value) {
            return;
          }

            router.push({ name: 'coupon_create' })
        }

        watch(facilityId, (newValue) => {
            if(newValue) tableCoupon.value.reset({ facilityId: newValue })
        })

        return {
            title,
            items,
            state,
            columns,
            viewCoupon,
            deleteCoupon,
            createCoupon,
            ADMIN_API_PATH,
            tableCoupon,
            facilityId,
            disabled
        }
    },
}
</script>

<style lang="scss">
.status {
    padding: 0px;
    border-radius: 7px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin-top: 0.4rem;
}

.status-valid {
    background: #E7F8EF;
    color: #18BC62;
}

.status-expired {
    background: #FDEEEE;
    color: #E95657;
}

.status-invalid {
    background: #FCF7ED;
    color: #E4B755;
}
</style>
